<template>
  <v-container id="login">
    <!-- Mobile -->
    <v-row class="hidden-md-and-up" justify="center">
      <v-slide-y-transition appear>
        <v-card
          class="card-resposive pa-0 ma-0 px-0 pt-6"
          style="width: 80%; padding: 0 10% 0 5%"
        >
          <v-img
            height="5%"
            contain
            src="../../../../assets/logo-imprimax.svg"
          ></v-img>
          <v-card-text class="pa-6 text-center">
            <div
              style="display: flex; justify-content: flex-start"
              class="benvenuto pb-3"
            >
              <span style="font-size: 2rem; padding: 5% 0 7% 5%"
                >Bem Vindo!</span
              >
            </div>

            <v-form
              style="padding: 0 10% 0 5%; text-align-last: left"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <div class="py-2">
                <span class="label">Login</span>
                <v-text-field
                  tabindex="1"
                  outlined
                  hide-details
                  class="primary-input"
                  v-model="email"
                  :rules="emailRules"
                  @keydown.enter="login()"
                  placeholder="Digite seu e-mail aqui"
                  required
                />
              </div>
              <div class="py-2">
                <span class="label">Senha</span>
                <v-text-field
                  outlined
                  tabindex="2"
                  hide-details
                  class="primary-input"
                  v-model="password"
                  :rules="passwordRules"
                  @keydown.enter="login()"
                  :type="show1 ? 'text' : 'password'"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                  placeholder="Digite sua senha aqui"
                  required
                />
              </div>
              <div class="d-flex py-2" style="justify-content: flex-end">
                <router-link
                  style="text-decoration: none; color: #bc0000"
                  to="forgotpassword"
                  >Esqueci minha senha</router-link
                >
              </div>
            </v-form>

            <div style="padding: 0 10% 5% 4%">
              <v-btn
                block
                @click="login()"
                :loading="loading"
                class="primary-button"
              >
                Login
              </v-btn>
            </div>
            <div class="d-flex" style="place-content: center">
              <p class="paragraf">Não tem cadastro?</p>

              <router-link
                style="padding: 0 0 0 5%; text-decoration: none; color: #bc0000"
                to="register"
                >Cadastre-se</router-link
              >
            </div>
          </v-card-text>
        </v-card>
      </v-slide-y-transition>
    </v-row>

    <!-- Desktop -->
    <v-row class="hidden-sm-and-down" justify="center">
      <v-slide-y-transition appear>
        <v-card
          class="card-resposive pa-0 ma-0 px-0 pt-6"
          style="width: 40%; padding: 0 10% 0 5%"
        >
          <v-img
            height="30px"
            contain
            src="../../../../assets/logo-imprimax.svg"
          ></v-img>
          <v-card-text class="pa-6 text-center">
            <div
              style="display: flex; justify-content: flex-start"
              class="benvenuto pb-3"
            >
              <span style="font-size: 2.5rem; padding: 5% 0 0% 5%"
                >Bem Vindo!</span
              >
            </div>

            <v-form
              style="padding: 0 10% 0 5%; text-align-last: left"
              class="py-2"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <div class="py-2">
                <span class="label">Login</span>
                <v-text-field
                  tabindex="1"
                  outlined
                  hide-details
                  class="primary-input my-2"
                  v-model="email"
                  :rules="emailRules"
                  @keydown.enter="login()"
                  placeholder="Digite seu e-mail aqui"
                  required
                />
              </div>
              <div class="py-2">
                <span class="label">Senha</span>
                <v-text-field
                  outlined
                  tabindex="2"
                  class="primary-input my-2"
                  hide-details
                  v-model="password"
                  :rules="passwordRules"
                  @keydown.enter="login()"
                  :type="show1 ? 'text' : 'password'"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                  color="secondary"
                  placeholder="Digite sua senha aqui"
                  required
                />
              </div>

              <div class="d-flex py-2" style="justify-content: flex-end">
                <router-link
                  style="
                    padding: 0 0 0 5%;
                    text-decoration: none;
                    color: #bc0000;
                  "
                  to="forgotpassword"
                  >Esqueci minha senha</router-link
                >
              </div>
            </v-form>

            <div style="padding: 0 10% 5% 4%">
              <v-btn
                block
                @click="login()"
                :loading="loading"
                class="btns-login"
                color="#BC0000"
              >
                <p style="color: white; margin-bottom: 0px">Login</p>
              </v-btn>
            </div>
            <div class="d-flex" style="place-content: center">
              <p class="paragraf">Não tem cadastro?</p>

              <router-link
                style="padding: 0 0 0 5%; text-decoration: none; color: #bc0000"
                to="register"
                >Cadastre-se</router-link
              >
            </div>
          </v-card-text>
        </v-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from "../../../../services/ApiService";
import Vue from "vue";

export default {
  name: "Login",

  components: {},

  data: () => ({
    prevRoute: null,
    show1: false,
    loading: false,
    error: false,
    valid: true,
    email: "",
    emailRules: [
      (v) =>
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          v
        ) || "E-mail inválido.",
    ],

    password: "",
    passwordRules: [(v) => !!v || "Senha requerida"],
    apiService: new ApiService(),
  }),

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },

  async created() {
    this.badge = JSON.parse(localStorage.getItem("cartBadge"));
    this.user = JSON.parse(localStorage.getItem("user"));
  },

  computed: {},

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    async login() {
      if (this.validate()) {
        this.error = null;
        var self = this;
        this.loading = true;
        const user = {
          Email: this.email,
          Password: this.password,
        };
        var client = new ApiService();

        await client
          .post("client/token", user)
          .then((result) => {
            if (result.user.status != 0 || result.user.type == 3) {
              const user = JSON.stringify(result.user);
              this.$store.commit("SET_LOGGED_USER", user);
              this.$store.commit("SET_CURRENT_TOKEN", result.token);
              this.$store.commit("SET_EXPIRES_TOKEN", result.validTo);
              this.$refs.form.reset();
              this.loading = false;

              const cart = JSON.parse(localStorage.getItem("cart"));

              if (cart && cart.items && cart.items.length != 0) {
                cart.userId = result.user.id;

                const newCart = {
                  userId: result.user.id,
                  items: cart.items.map((x) => {
                    return {
                      skuId: x.skuId,
                      quantity: x.quantity,
                      promotionId: x.promotionId,
                    };
                  }),
                };

                client.post("cart/addcart", newCart).then(() => {
                  localStorage.removeItem("cart");
                  setTimeout(() => {
                    this.$router.push("/cart");
                  }, 1000);
                });
              }

              this.$router.push("/home");

              window.location.reload();
            } else {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              this.$toast.error("Email ou senha incorretos");
            }
          })
          .catch((erro) => {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            this.$toast.error("Email ou senha incorretos");
          });

        self.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.label {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #979797;
  padding-left: 10px;
}
.paragraf {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.15px;

  color: #979797;
}
.router-link {
  font-size: 85%;
}

.btns-login {
  display: flex;
  color: #bc0000;
  justify-content: space-around;
  width: 85%;
  margin: auto;
}

.router-link:hover {
  font-size: 14px;
  color: #4e94ec;
  text-decoration: underline;
}

.benvenuto {
  font-family: Roboto;
  font-size: 140%;
  color: #2d3038;
}

.right {
  text-align: -webkit-right;
}
.card-resposive {
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
}
@media (max-width: 600px) {
  /* .card-resposive {
    width: 60%;
    margin: auto;

  } */
}
#login {
  font-family: Poppins, serif !important;
}
</style>
